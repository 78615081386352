<template>
  <div class="validation-explanation column">
    <template v-for="(conflict, conflictIdx) in explanation.conflicts" :key="conflictIdx">
      <mi-list
        v-for="explanationDetail in conflict.explanations"
        :key="explanationDetail.ruleId"
        class="validation-explanation__card bg-white q-py-sm"
        bordered
      >
        <!-- Rule ID -->
        <mi-list-item-label class="q-py-sm" header>
          <p
            v-if="explanationDetail.ruleId.includes('WTPart')"
            class="validation-explanation-item__link text-body2 text-weight-bold"
          >
            Rule ID {{ explanationDetail.ruleId }}
          </p>
          <a
            v-else
            class="validation-explanation-item__link validation-explanation-item__hover text-body2 text-weight-bold"
            role="button"
            tabindex="0"
            @click="redirectToWindchill(explanationDetail.ruleId)"
          >
            Rule ID {{ explanationDetail.ruleId }}
          </a>
        </mi-list-item-label>

        <!-- Condition & Conclusion -->
        <mi-list-item
          v-for="detailName in detailNames"
          :key="detailName"
          class="validation-explanation-item"
        >
          <!-- Detail title -->
          <mi-list-item-section class="validation-explanation-item__title q-pr-sm" side>
            <mi-list-item-label class="text-weight-medium text-primary text-capitalize">
              {{ detailName }}:
            </mi-list-item-label>
          </mi-list-item-section>

          <!-- Detail content -->
          <mi-list-item-section class="text-weight-light">
            {{ explanationDetail[detailName] }}
          </mi-list-item-section>
        </mi-list-item>
      </mi-list>
    </template>

    <!-- No found caption -->
    <div v-if="!explanation.conflicts?.length" class="flex">
      <mi-icon name="warning-circle" size="1.5rem"></mi-icon>
      <h6 class="q-my-none q-ml-sm"> No explanation found </h6>
    </div>

    <!-- Relevant choices -->
    <mi-list
      v-if="explanation.relevantChoices?.length"
      class="validation-explanation__card bg-white q-py-sm"
      bordered
    >
      <mi-list-item>
        <mi-list-item-section>
          <!-- Title -->
          <mi-list-item-label class="q-pt-none q-pb-md">
            <h6 class="text-body2 text-weight-bold no-margin"> Relevant Choices: </h6>
          </mi-list-item-label>

          <!-- Choices list -->
          <ul class="validation-explanation__list no-margin no-padding text-weight-light">
            <li v-for="(choice, choiceIdx) in explanation.relevantChoices" :key="choiceIdx">
              {{ choice.id2 }} - {{ choice.name }}
            </li>
          </ul>
        </mi-list-item-section>
      </mi-list-item>
    </mi-list>
  </div>
</template>

<script>
  import { CHOICE_RULE_WINDCHILL_LINK } from '@/constants'
  import { createWindchillLink } from '@/utils/windchill'

  export default {
    name: 'ValidationExplanation',
    props: {
      explanation: {
        type: Object,
        required: true
      }
    },
    data: () => ({
      detailNames: ['condition', 'conclusion']
    }),
    methods: {
      redirectToWindchill(ruleId) {
        const windchillLink = createWindchillLink(CHOICE_RULE_WINDCHILL_LINK, ruleId)
        if (windchillLink) window.open(windchillLink, '_blank')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .validation-explanation {
    gap: .5rem;

    &__card {
      border-color: $mi-anthracite-100;
    }

    &__list {
      list-style-type: none;
    }
  }

  .validation-explanation-item.mi-list-item {
    min-height: auto;
  }

  .validation-explanation-item__title {
    min-width: 100px;
  }

  .validation-explanation-item__link {
    color: $mi-anthracite-800;
    margin-top: 0;
    margin-bottom: 0;
  }

  .validation-explanation-item__hover {
    &:hover {
      color: $mi-red-500;
      cursor: pointer;
    }
  }
</style>
